import React from 'react'

interface BenifetProps {

}

export const Benifet: React.FC<BenifetProps> = ({ }) => {
  return (
    <>
      好康優惠
    </>
  );
}
import React from 'react'

interface AboutUsProps {

}

export const AboutUs: React.FC<AboutUsProps> = ({ }) => {
  return (
    <>
      關於影城
    </>
  );
}